/*jshint esversion: 6 */
// import Vue from 'vue';
// import axios from 'axios';
// import {extractErrorDetails} from '@/helpers/util.js';

const initialState = () => ({
//ids
  fake_id: 100,

//types:

  // etc
  error: ''
});

// State object
const state = initialState();
// Getter functions
const getters = {
  getUDObjects: (state, getters, rootState) => (payload) => {
    if (payload.ids) {
      return payload.ids.map(id => rootState.Ultra[payload.type][id]);
    } else if (payload.types) {
      let array = [];
      payload.types.forEach(type => {
        array = array.concat(Object.keys(rootState.Ultra[type]).map(id => rootState.Ultra[type][id]));
      });
      return array;
    } else {
      return Object.keys(rootState.Ultra[payload.type]).map(id => rootState.Ultra[payload.type][id]);
    }
  },
  getUDObjectsFiltered: (state, getters, rootState, rootGetters) => (payload) => {
    let array = [];
    if (payload.ids) {
      array = payload.ids.map(id => rootState.Ultra[payload.type][id]);
    } else if (payload.types) {
      payload.types.forEach(type => {
        if (payload.workbench && ['csequences', 'cworkpackages'].includes(type)) {
          array = array.concat(Object.keys(rootState.Ultra[type])
            .map(id => rootState.Ultra[type][id])
            .filter(object => object.likes.findIndex(like => like.user.id === rootGetters['Auth/getUserId']) !== -1
          ));
        } else {
          array = array.concat(Object.keys(rootState.Ultra[type]).map(id => rootState.Ultra[type][id]));
        }
      });
    } else {
      array = Object.keys(rootState.Ultra[payload.type]).map(id => rootState.Ultra[payload.type][id]);
    }
    return array.filter(object => {
      let boolean = true;
      if (payload.search) {
        boolean = false;
      }
      // search: einer der search props passt
      for (let prop in payload.search) {
        if (typeof object[prop] === 'string' && object[prop].toLowerCase().includes(payload.search[prop].toLowerCase())) {
          boolean = true;
        } else if (object[prop].includes(payload.search[prop])) {
          boolean = true;
        }
      }
      // filter: alle props passen
      for (let prop in payload.filter) {
        if (prop === 'owner' && object[prop].id !== payload.filter[prop].id) {
          boolean = false;
        } else if (prop !== 'owner' && object[prop] !== payload.filter[prop]) {
          boolean = false;
        }
      }
      return boolean;
    });
  },
  getUDObject: (state, getters, rootState) => (payload) => {
    return rootState.Ultra[payload.type][payload.id];
  },
};
// Actions
const actions = {
  createUDObject({commit}, payload) {
    return new Promise((resolve) => {
        //set fake ids
        for (let prop in payload.resp_data) {
          payload.resp_data[prop].forEach(object => {
            object.id = JSON.parse(JSON.stringify(state.fake_id));
            commit('increase_fake_id');
            }
          );
        }
        commit('Ultra/create_u_objects', payload, {root: true});
        if (payload.url.length > 2) {
          commit('Ultra/add_u_object', payload, {root: true});
        }
        if (payload.url.length > 1) {
          // resolve(resp.data.data[Object.keys(payload.url[payload.url.length-2])][0].id);
          resolve(payload.resp_data[Object.keys(payload.url[payload.url.length-2])][0].id);
        } else {
          resolve();
        }
    });
  },
  createUDObjectNoFake({commit}, payload) {
    console.log('PAYLOAD', payload);
    return new Promise((resolve) => {
        commit('Ultra/create_u_objects', payload, {root: true});
        if (payload.url.length > 2) {
          commit('Ultra/add_u_object', payload, {root: true});
        }
        if (payload.url.length > 1) {
          // resolve(resp.data.data[Object.keys(payload.url[payload.url.length-2])][0].id);
          resolve(payload.resp_data[Object.keys(payload.url[payload.url.length-2])][0].id);
        } else {
          resolve();
        }
    });
  },
  updateUDObject({commit}, payload) {
    return new Promise((resolve) => {
        commit('Ultra/update_u_object', payload, {root: true});
        resolve(Object.values(payload.url[payload.url.length-1])[0]);
    });
  },
  deleteUDObject({commit}, payload) {
    if (payload.url.length > 1) {
      commit('Ultra/remove_u_object', payload, {root: true});
    }
    commit('Ultra/delete_u_object', payload, {root: true});
  },
  addUDObjects({commit}, payload) {
    return new Promise((resolve) => {
        commit('Ultra/add_u_objects', payload, {root: true});
        resolve();
    });
  },
  removeUDObjects({commit}, payload) {
    return new Promise((resolve) => {
        commit('Ultra/remove_u_objects', payload, {root: true});
        resolve();
    });
  },
  updateUDObjectPosition({commit}, payload) {
    // commit before call due to performance
    commit('Ultra/update_u_object_position', payload, {root: true});
  },
  changeUDObjectParent({commit}, payload) {
    return new Promise((resolve) => {
        commit('Ultra/change_u_object_parent', payload, {root: true});
        resolve();
    });
  },
  updateUDObjectParent({commit}, payload) {
    return new Promise((resolve) => {
        commit('Ultra/update_u_object_parent', payload, {root: true});
        resolve();
    });
  },
};

// Mutations
const mutations = {
  set_error(state, error) {
    state.error = error;
  },
  increase_fake_id(state) {
    state.fake_id++;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
