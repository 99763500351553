import store from '@/store/index';
export const Navigation = {
  methods: {
    getHref(route_name) {
      if(route_name) {
        let href = this.$router.resolve({
          name: route_name,
        }).href;
        return href;
      }
    },

    getCategoryHref(category_slug) {
      if(category_slug) {
        let href = this.$router.resolve({
          name: 'Category',
          params: {
            category_slug: category_slug,
          }
        }).href;
        return href;
      }
    },

    getArticleHref(category_slug, article_id, is_admin) {
      if(category_slug && article_id) {
        let href = this.$router.resolve({
          name: `${ is_admin ? 'Admin' : '' }Article`,
          params: {
            category_slug: category_slug,
            article_id: article_id,
          }
        }).href;
        return href;
      }
    },

    navigateToRoute(route_name) {
      if(route_name && route_name != this.$router.currentRoute.name) {
        this.$router.push({
          name: route_name,
        });
      }
    },

    navigateToCategory(category_slug) {
      if(category_slug) {
        this.$router.push({
          name: 'Category',
          params: {
            category_slug: category_slug,
          },
        });
      }
    },

    navigateToSearch(search_params) {
      if(search_params) {
        if(this.$router.currentRoute.name != 'Search') {
          store.dispatch('Knowledge/requestSearchResults', { search_params: search_params }).then(() => {
            this.$router.push({
              name: 'Search',
              params: {
                search_params: search_params,
              },
            });
          });
        }
        else {
          if(search_params != this.getLatestSearchTerms()) {
            store.dispatch('Knowledge/requestSearchResults', { search_params: search_params }).then(() => {
              this.$router.replace({
                name: 'Search',
                params: {
                  search_params: search_params,
                },
              });
            });
          }
        }
      }
    },

    navigateToArticle(category_slug, article_id, is_admin) {
      if(category_slug && article_id) {
        this.$router.push({
          name: `${ is_admin ? 'Admin' : '' }Article`,
          params: {
            category_slug: category_slug,
            article_id: article_id,
          }
        });
      }
    },
  },
};
