/*jshint esversion: 6 */

import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

// import the auto exporter
import modules from './modules';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';

let mutations = [];
let actions = [];

const bugReportActionLogger = (store) => {
    store.subscribeAction((action) => {
      if (
        action.type !== 'Auth/login' &&
        action.type !== 'BugReport/sendBugReport' &&
        action.type !== 'BugReport/toggleBugDialog' &&
        action.type !== 'BugReport/appendAction' &&
        action.type !== 'BugReport/appendMutation'
      ) {
        actions.push({
          name: action.type,
          payload: action.payload
        });
        if (actions.length > 10) {
          actions.shift();
        }
        // store.dispatch('BugReport/appendAction', {
        //   name: action.type,
        //   payload: action.payload
        // });
      }
    });
  };

const bugReportMutationLogger = (store) => {
    store.subscribe((mutation) => {
      if (mutation.type.endsWith('set_error')) {

        if (mutation.payload.msg === 'given token not valid!') {
          store.dispatch('Auth/refreshAccessToken', actions[actions.length -1]);
        } else {
          // send out bug report automatically
          if (mutation.payload.status >= 410) {
            store.dispatch('BugReport/sendBugReport', {
              error: mutation.payload,
              mutations: mutations,
              actions: actions
            });
          }
          // don't show the bug dialog
          // store.dispatch('BugReport/toggleBugDialog', {
          //   show: true,
            // payload: {
            //   error: mutation.payload,
            //   mutations: mutations,
            //   actions: actions
            // }
          // });
          mutations = [];
          actions = [];
        }
      }


      if (
        mutation.type !== 'BugReport/toggle_bug_dialog' &&
        mutation.type !== 'BugReport/append_action' &&
        mutation.type !== 'BugReport/append_mutation'
      ) {
        mutations.push({
          name: mutation.type,
          payload: mutation.payload
        });
        if (mutations.length > 10) {
          mutations.shift();
        }
        // store.dispatch('BugReport/appendMutation', {
        //   name: mutation.type,
        //   payload: mutation.payload
        // });
      }
    });
  };

export default new Vuex.Store({
  // plugins: [vuexLogger, ],
  plugins: debug ? [createLogger(), bugReportActionLogger, bugReportMutationLogger] : [bugReportActionLogger, bugReportMutationLogger], // set logger only for development
  // plugins: debug ? [createLogger()] : [], // set logger only for development
  modules, // all your modules automatically imported :)
  actions: {
    reset({commit}){
      Object.keys(modules).forEach(moduleName => {
        commit(`${moduleName}/reset`);
      });
    }
  },
  strict: debug
});
