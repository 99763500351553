/*jshint esversion: 6 */
/*jshint sub:true*/

import Vue from 'vue';
import axios from 'axios';
import router from '@/router/index';
import {extractErrorDetails} from '@/helpers/util.js';
import store from '@/store/index';

// function parseJwt(token) {
//   var base64Url = token.split('.')[1];
//   var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//   var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
//     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//   }).join(''));
//   return JSON.parse(jsonPayload);
// }
//
// function getIdFromToken(token) {
//   return parseJwt(token).data.id;
// }

const initialState = () => ({
  current_user: {
    'id': 0,
    'email': '',
    'image': '',
    'phone_number': '',
    'first_name': '',
    'last_name': ''
  },
  error: {
    status: '',
    statusText: '',
    msg: ''
  },
  status: '',
  access_token: localStorage.getItem('TAKT_access_token') || '',
  id_token: localStorage.getItem('TAKT_id_token') || '',
  cloudprint_token: '',
  refresh_token: localStorage.getItem('TAKT_refresh_token') || '',
  permissions: [],
  project_permissions: {},
  refresh_task: {},
  refresh_blocker: 0,
  cloudprint_cache: {},
  cloudprint_error: 'Loading Cloudprint...',
});

// State object
const state = initialState();
// Getter functions
const getters = {
  authStatus: ( state ) => state.status,
  getError_message: (state) => state.error.msg,
  getUserId: ( state ) => state.current_user.id,
  getUser: ( state ) => state.current_user,
  getUserLiveSync: ( state ) => {
    return {
      id: state.current_user.id,
      first_name: state.current_user.first_name,
      last_name: state.current_user.last_name,
      image: state.current_user.image,
      email: state.current_user.email
    };
  },
  getToken: ( state ) => state.access_token,
  getIDToken: ( state ) => state.id_token,
  getRefreshBlocker: ( state ) => state.refresh_blocker,
  hasPermission: ( state ) => ( permission ) => {
    return state.permissions.includes(permission);
  },
  hasOneOfPermissions: ( state ) => ( permissions ) => {
    for (var i = 0; i < permissions.length; i++) {
      if ( state.permissions.includes(permissions[i]) ) {
        return true;
      }
    }
    return false;
  },
  hasAllOfPermissions: ( state ) => ( permissions ) => {
    for (var i = 0; i < permissions.length; i++) {
      if ( !state.permissions.includes(permissions[i]) ) {
        return false;
      }
    }
    return true;
  },
  isLoading: ( state ) => state.status === 'loading',
  isLoggedIn: ( state ) => !!state.access_token,
  isCloudPrint: ( state ) => !!state.cloudprint_token && !state.access_token || !!state.cloudprint_token && state.access_token && !state.cloudprint_cache.is_my_project,
};
// Actions
const actions = {
  refreshAccessToken({commit, dispatch, getters}, payload){
    let refresh_blocker = getters.getRefreshBlocker;
    if (refresh_blocker === 0 || Math.floor(Date.now() / 1000) - refresh_blocker > 10) {
      // last refresh hasn't happened yet or is older than 10 seconds
      let oauth_axios = axios.create({
        baseURL: process.env.VUE_APP_OAUTH_BASE_URL,
        timeout: 1000
      });
      let params = {
        grant_type:'refresh_token',
        client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
        refresh_token: localStorage.getItem('TAKT_refresh_token'),
        redirect_uri: process.env.VUE_APP_REDIRECT + '/aws-auth/',
        scope: 'openid'
      };

      const data = Object.entries(params).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&');
      oauth_axios({
        url: 'oauth2/token',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: data
      })
      .then(resp => {
        localStorage.setItem('TAKT_access_token', resp.data.access_token);
        localStorage.setItem('TAKT_id_token', resp.data.id_token);
        // localStorage.setItem('TAKT_refresh_token', resp.data.refresh_token);
        axios.defaults.headers.common['TAKTToken'] = resp.data.access_token;
        axios.defaults.headers.common['TAKTIDToken'] = resp.data.id_token;
        commit('set_refresh_blocker', Math.floor(Date.now() / 1000));
        commit('auth_refresh', {
          access_token: resp.data.access_token,
          id_token: resp.data.id_token
        });
        if (payload) {
          dispatch(payload.name, payload.payload, { root: true });
        } else {
          setTimeout(() => dispatch('refreshAccessToken', false), 28 * 60000);
        }
      })
      .catch(() => {
        dispatch('logout');
        // commit('set_error_message', extractErrorDetails(err));
      });
    }
  },
  requestAccessToken({commit, dispatch}, payload) {
    let oauth_axios = axios.create({
      baseURL: process.env.VUE_APP_OAUTH_BASE_URL,
      timeout: 1000
    });

    let params = {
      grant_type:'authorization_code',
      client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
      code: payload.code,
      redirect_uri: process.env.VUE_APP_REDIRECT + '/aws-auth/',
      scope: 'openid'
    };

    const data = Object.entries(params).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&');
    oauth_axios({
      url: 'oauth2/token',
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    })
    .then(resp => {
      localStorage.setItem('TAKT_access_token', resp.data.access_token);
      localStorage.setItem('TAKT_id_token', resp.data.id_token);
      localStorage.setItem('TAKT_refresh_token', resp.data.refresh_token);
      axios.defaults.headers.common['TAKTToken'] = resp.data.access_token;
      axios.defaults.headers.common['TAKTIDToken'] = resp.data.id_token;
      axios({
        url: 'users/0/callinguser',
        method: 'GET',
        headers: {
          'Cache-Control': 'no-cache'
        }
      })
      .then(resp_user => {
        axios({
          url: 'users/' + resp_user.data.data.id + '/permissions',
          method: 'GET'
        })
        .then(resp_permissions => {
          commit('set_current_user', resp_user.data.data);
          commit('set_user_permissions', resp_permissions.data.data);
          commit('auth_success', {
            access_token: resp.data.access_token,
            id_token: resp.data.id_token,
            refresh_token: resp.data.refresh_token
          });
          setTimeout(() => dispatch('refreshAccessToken', false), 28 * 60000);
          let redirect_query = localStorage.getItem('TAKT_redirect_route');
          localStorage.setItem('TAKT_redirect_route', 'no_redirect');
          if (!redirect_query || redirect_query === 'no_redirect') {
            router.push({ name: 'ArticlesDashboard' }).catch(() => {});
          } else {
            router.push(redirect_query);
          }

        })
        .catch(err_permissions => {
          commit('set_error', extractErrorDetails(err_permissions));
        });
      })
      .catch(err => {
        commit('set_error', extractErrorDetails(err));
      });
    })
    .catch(err => {
      commit('set_error_message', extractErrorDetails(err));
    });
  },
  logout({
    commit, /*dispatch*/
  }) {
    localStorage.removeItem('TAKT_access_token');
    localStorage.removeItem('TAKT_id_token');
    localStorage.removeItem('TAKT_refresh_token');
    axios.defaults.headers.common['TAKTToken'] = '';
    axios.defaults.headers.common['TAKTIDToken'] = '';
    commit('logout');

    if(router.currentRoute.name != 'Home') {
      router.push({ name: 'Home' });
    }
    store.dispatch('reset', null);
  },
  initUser({state, commit}) {
    return new Promise((resolve, reject) => {
      axios.defaults.headers.common['TAKTToken'] = state.access_token;
      axios.defaults.headers.common['TAKTIDToken'] = state.id_token;
      axios({
        url: 'users/0/callinguser',
        method: 'GET'
      })
      .then(resp_user => {
        axios({
          url: 'users/' + resp_user.data.data.id + '/permissions',
          method: 'GET'
        })
        .then(resp_permissions => {
          commit('set_current_user', resp_user.data.data);
          commit('set_user_permissions', resp_permissions.data.data);
          resolve();
          // router.push({ name: 'UserHome' });
        })
        .catch(err_permissions => {
          commit('set_error', extractErrorDetails(err_permissions));
          reject();
        });
      })
      .catch(err => {
        commit('set_error', extractErrorDetails(err));
        reject();
      });
    });
  },
  getCloudPrintByLink({commit, getters}, link) {
    // return new Promise((resolve, reject) => {
    let access_token = localStorage.getItem('TAKT_access_token') || '';
    let id_token = localStorage.getItem('TAKT_id_token') || '';


    axios({
      url: 'projects/0/cloudprint?link=' + link,
      method: 'GET',
      headers: {
        'TAKTToken': access_token,
        'TAKTIDToken': id_token,
      },
    })
    .then(resp => {
      console.log(resp);
      let cloudprint = resp.data.data;
      commit('set_cloudprint_cache', cloudprint);
      commit('set_cloudprint_token', cloudprint.token);
      if (cloudprint.settings.init_route_name === 'ProjectTaktPlan') {
        commit('View/set_taktplan_zoom', cloudprint.settings.taktplan_zoom, { root: true });
        document.body.style.setProperty('--taktplan_zoom', `${cloudprint.settings.taktplan_zoom}`);
        commit('ProjectStatistics/set_complete_statistic_settings', cloudprint.settings.statistic_settings, { root: true});
      }
      router.push({
        name: cloudprint.settings.init_route_name,
        params: {
          project_id: cloudprint.settings.project_id,
          open_cloudprint: true,
        }
      });
      if (getters.isCloudPrint) {
        commit('set_cloudprint_error');
      }
      // resolve();
    })
    .catch(err => {
      commit('set_error', extractErrorDetails(err));
      // reject();
    });
    // });
  },
  resetCloudPrint({commit, rootState}) {
    return new Promise((resolve) => {
      commit('reset_cloud_print', rootState);
      resolve();
    });
  }
};
// Mutations
const mutations = {
  auth_request(state) {
    state.status = 'loading';
  },
  auth_success(state, payload) {
    state.status = 'success';
    state.access_token = payload.access_token;
    state.id_token = payload.id_token;
    state.refresh_token = payload.refresh_token;
  },
  auth_refresh(state, payload) {
    state.status = 'success';
    state.access_token = payload.access_token;
    state.id_token = payload.id_token;
  },
  reset(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },
  set_current_user(state, current_user) {
    state.current_user = current_user;
  },
  auth_error(state) {
    state.status = 'error';
  },
  set_error(state, error) {
    state.error = error;
  },
  logout(state) {
    state.status = '';
    state.access_token = '';
    state.id_token = '';
    state.refresh_token = '';
  },
  set_refresh_blocker(state, refresh_blocker){
    state.refresh_blocker = refresh_blocker;
  },
  set_user_permissions(state, permissions){
    state.permissions = [...permissions];
  },
  set_user_project_permissions(state, payload){
    Vue.set(state.project_permissions, payload.project_id, [...payload.ppermissions]);
  },
  update_email(state, email){
    state.current_user.email = email;
  },
  update_phone_number(state, phone_number){
    state.current_user.phone_number = phone_number;
  },
  update_first_name(state, first_name){
    state.current_user.first_name = first_name;
  },
  update_last_name(state, last_name){
    state.current_user.last_name = last_name;
  },
  update_image(state, image){
    state.current_user.image = image;
  },
  reset_status(state){
    state.status = '';
    state.error_message = '';
  },
  refresh_task(state, task){
    state.refresh_task = task;
  },
  set_cloudprint_token(state, payload) {
    state.cloudprint_token = payload;
  },
  set_cloudprint_cache(state, payload) {
    state.cloudprint_cache = payload;
  },
  reset_cloud_print(state, rootState) {
    delete rootState.Ultra[state.cloudprint_cache.settings.project_id];
    state.cloudprint_token = '';
    state.cloudprint_cache = {};
    state.cloudprint_error = 'Loading Cloudprint...';
  },
  set_cloudprint_error(state) {
    state.cloudprint_error = 'Error loading Cloutprint (e.g. CP Not Public, Access Time Restrictions, Inactive CP Link)';
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
