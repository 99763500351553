<template>
  <div>
    <v-btn
      v-if="!isLoggedIn"
      small
      outlined
      :href="login_url"
    >
      <p>login</p>
    </v-btn>
    <v-btn
      v-else
      small
      outlined
      @click="logout()"
    >
      <p>logout</p>
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
      ...mapGetters('Auth', ['isLoggedIn', ]),
  },

  data: () => ({
      login_url:  process.env.VUE_APP_LOGIN_URL,
  }),

  methods: {
      ...mapActions('Auth', ['logout', ]),
  },
}
</script>

<style lang="css" scoped>
.v-btn {
  text-transform: none;
  letter-spacing: normal;
}
</style>
