import { mapGetters, mapActions } from 'vuex';
export const Knowledge = {
  computed: {
    ...mapGetters(
      'Knowledge',
      [
        'getLanguages', 'getSelectedLanguage', 'getLatestArticles',
        'getLatestSearchTerms', 'getMatchedSimplePotentialAuthors',
      ]
    ),
  },

  methods: {
    ...mapActions(
      'Knowledge',
      [
        'setLanguage', 'uploadImage',
        'setLatestSearchResult', 'setLatestSearchTerms', 'setLatestArticles',
        'requestLatestArticles', 'requestCategoryBySlug', 'requestSearchResults',
        'cleanHashtagArray', 'updatePotentialAuthors',
      ]
    ),
  },
};
