import { mapGetters, mapActions } from 'vuex';
export const Ultra = {
  computed: {
    ...mapGetters(
        'Ultra',
        [
          'getUObjects', 'getUObjectsFiltered', 'getUObject',
          'getUrl', 'isUObjEmpty',
        ]
      ),
    ...mapGetters(
      'Auth',
      [
        'hasPermission', 'hasOneOfPermissions', 'hasAllOfPermissions',
      ]
    ),
  },

  methods: {
    ...mapActions('UltraDev', ['updateUDObject', 'deleteUDObject', 'createUDObject', ]),
    ...mapActions(
      'Ultra',
      [
        'requestUObject', 'createUObject', 'updateUObject',
        'updateUObjectPosition', 'updateUObjectParent', 'deleteUObject',
        'addUObjects', 'removeUObjects', 'resetUState', 'updateUObjectOrderIndex'
      ]
    ),
  },
};
