export const Helpers = {
  methods: {
    generateSearchString(words) {
      let search_string = '';
      for(let i = 0; i < words.length; i++) {
        search_string += `&search[]=${words[i]}`;
      }
      return search_string;
    },
  },
};
