/*jshint esversion: 9 */
import Vue from 'vue';
import axios from 'axios';
import {extractErrorDetails} from '@/helpers/util.js';

const initialState = () => ({
  categories: {},
  articles: {},
  textblocks: {},
  mediablocks: {},
  hashtags: {},
  definitions: {
    articles: {
      blocks: [],
    },
  },
});


const state = initialState();

const getters = {
  getUObjects: (state) => (payload) => {
    if (payload.ids) {
      return payload.ids.map(id => state[payload.type][id]);
    } else if (payload.types) {
      let array = [];
      payload.types.forEach(type => {
        array = array.concat(Object.keys(state[type]).map(id => state[type][id]));
      });
      return array;
    } else {
      return Object.keys(state[payload.type]).map(id => state[payload.type][id]);
    }
  },
  getUObjectsFiltered: (state, getters, rootState, rootGetters) => (payload) => {
    let array = [];
    if (payload.ids) {
      array = payload.ids.map(id => state[payload.type][id]);
    } else if (payload.types) {
      payload.types.forEach(type => {
        if (payload.workbench && ['csequences', 'cworkpackages'].includes(type)) {
          array = array.concat(Object.keys(state[type])
            .map(id => state[type][id])
            .filter(object => object.likes.findIndex(like => like.user.id === rootGetters['Auth/getUserId']) !== -1
          ));
        } else {
          array = array.concat(Object.keys(state[type]).map(id => state[type][id]));
        }
      });
    } else {
      array = Object.keys(state[payload.type]).map(id => state[payload.type][id]);
    }
    return array.filter(object => {
      let boolean = true;
      if (payload.search) {
        boolean = false;
      }
      for (let prop in payload.search) {
        if (object[prop].toLowerCase().includes(payload.search[prop].toLowerCase())) {
          boolean = true;
        }
      }
      for (let prop in payload.filter) {
        if (prop === 'owner' && object[prop].id !== payload.filter[prop].id) {
          boolean = false;
        } else if (prop !== 'owner' && object[prop] !== payload.filter[prop]) {
          boolean = false;
        }
      }
      return boolean;
    });
  },
  getUObject: (state) => (payload) => {
    return state[payload.type][payload.id];
  },
  getUrl: () => (payload) => {
    let payload_copy = JSON.parse(JSON.stringify(payload));
    let url = '';
    if (payload_copy.url_prefix) {
      url = payload_copy.url_prefix;
    }
    //takt-space
    if (payload_copy.url.length > 1 && ['sites', 'spatialzones', 'buildings', 'buildingstoreys', 'spacegroups', 'elements'].includes(Object.keys(payload_copy.url[1])[0])) {
      payload_copy.url.splice(0,1);
    }
    payload_copy.url.forEach(entry =>
      url = `${url}${Object.keys(entry)[0].substring(0, 4) !== 'show'? '/':'?show='}${Object.keys(entry)[0]}${Object.values(entry)[0] !== ''?`/${Object.values(entry)[0]}`:''}`
    );
    if (payload.url_suffix) {
      url = `${url}${payload.url_suffix}`;
    }
    return url;
  },
  isUObjEmpty: (state) => (payload) => {
    return Object.entries(state[payload]).length === 0;
  },
};

// Actions
const actions = {
  requestUObject({ commit, getters, rootState, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      let headers = {
        'Cache-Control': 'no-cache'
      };
      if (rootGetters['Auth/isCloudPrint']) {
        headers.TAKTCloudprintToken = rootState.Auth.cloudprint_token;
      }
      axios({
        url: getters.getUrl(payload),
        method: 'GET',
        headers: headers,
      })
      .then(resp => {
        payload.resp_data = resp.data.data;
        commit('create_u_objects', payload);
        if (payload.add_u_object) {
          commit('add_u_object', payload);
        }
        resolve(resp.data.data);
      })
      .catch(err => {
        commit('set_error', extractErrorDetails(err));
        reject();
      });
    });
  },
  createUObject({commit, getters, /*rooState*/}, payload){
    return new Promise((resolve, reject) => {
      let data = {
        // edit_key: rootState.Project.edit_key
      };
      if (payload.bulk) {
        data = Object.assign(data, payload.bulk.targets, payload.bulk.parents);
      } else {
        data = Object.assign(data, payload.u_object);
      }
      axios({
        url: getters.getUrl(payload),
        method: 'POST',
        data: payload.image?payload.image:data,
        headers: {
          'Cache-Control': 'no-cache'
        },
      })
      .then(resp => {
        // payload.resp_data = {wworkpackageimages: [resp.data.data]};
        payload.resp_data = resp.data.data;
        commit('create_u_objects', payload);
        if (payload.url.length > 2) {
          commit('add_u_object', payload);
        }
        if (payload.url.length > 1) {
          resolve(resp.data.data[Object.keys(payload.url[payload.url.length-2])][0].id);
        } else {
          resolve();
        }
      })
      .catch(err => {
        commit('set_error', extractErrorDetails(err));
        reject();
      });
    });
  },
  updateUObject({commit, getters, rootState}, payload) {
    return new Promise((resolve, reject) => {
      let data = {
        // edit_key: rootState.Project.edit_key
      };
      if (payload.bulk) {
        data = Object.assign(data, payload.bulk.targets);
        data.updates = payload.bulk.updates;
      } else {
        data = Object.assign(data, payload.update);
      }
      axios({
        url: getters.getUrl(payload),
        method: 'PUT',
        data: data,
      })
      .then(resp => {
        if (resp.data) {
          // commit('Autopilot/set_autopilots', resp.data.autopilot_data, { root: true });
        }

        // ADDITION: rootState for add#authors
        if(payload.update['add#authors']) {
          payload.update.potential_authors = rootState.Knowledge.potential_authors;
        }
        // END ADDITION

        commit('update_u_object', payload);
        resolve();
      })
      .catch(err => {
        commit('set_error', extractErrorDetails(err));
        reject();
      });
    });
  },
  updateUObjectPosition({commit, getters, /*rooState*/}, payload) {
    return new Promise((resolve, reject) => {
      // commit before call due to performance
      commit('update_u_object_position', payload);
      let data = {
        // edit_key: rootState.Project.edit_key
      };
      if (payload.bulk) {
        data = Object.assign(data, payload.bulk.targets);
        data.updates = payload.bulk.updates;
      } else {
        data = Object.assign(data, payload.update);
      }
      axios({
        url: getters.getUrl(payload),
        method: 'PUT',
        data: data
      })
      .then(() => {
        resolve();
      })
      .catch(err => {
        commit('set_error', extractErrorDetails(err));
        reject();
      });
    });
  },
  // ADDITION: MOVE ARTICLES REACTIVLY IN ADMIN
  updateUObjectOrderIndex({commit, getters, /*rooState*/}, payload) {
    return new Promise((resolve, reject) => {
      // commit before call due to performance
      commit('update_u_object_order_index', payload);
      axios({
        url: getters.getUrl(payload),
        method: 'PUT',
        data: payload.update,
      })
      .then(() => {
        resolve();
      })
      .catch(err => {
        commit('set_error', extractErrorDetails(err));
        reject();
      });
    });
  },
  // END ADDITION
  updateUObjectParent({commit, getters, /*rooState*/}, payload) {
    return new Promise((resolve, reject) => {
      let data = {
        // edit_key: rootState.Project.edit_key
      };
      if (payload.bulk) {
        data.targets = payload.bulk.targets;
        data.parents = payload.bulk.parents;
      } else {
        data = Object.assign(data, payload.update);
      }
      axios({
        url: getters.getUrl(payload),
        method: 'PUT',
        data: data
      })
      .then(() => {
        commit('update_u_object_parent', payload);
        resolve();
      })
      .catch(err => {
        commit('set_error', extractErrorDetails(err));
        reject(err);
      });
    });
  },
  deleteUObject({commit, getters, /*rooState*/}, payload) {
    return new Promise((resolve, reject) => {
      let data = {
        // edit_key: rootState.Project.edit_key
      };
      if (payload.bulk) {
        data = Object.assign(data, payload.bulk.targets);
      }
      axios({
        url: getters.getUrl(payload),
        method: 'DELETE',
        headers: {
          'Cache-Control': 'no-cache'
        },
        data: data
      })
      .then(() => {
        if (payload.url.length > 1) {
          commit('remove_u_object', payload);
        }
        commit('delete_u_object', payload);
        resolve();
      })
      .catch(err => {
        commit('set_error', extractErrorDetails(err));
        reject();
      });
    });
  },
  addUObjects({commit, getters, /*rooState*/}, payload) {
    return new Promise((resolve, reject) => {
      let data = {
        // edit_key: rootState.Project.edit_key
      };
      data = Object.assign(data, payload.u_objects);
      axios({
        url: getters.getUrl(payload),
        method: 'PUT',
        data: data,
      })
      .then(() => {
        commit('add_u_objects', payload);
        resolve();
      })
      .catch(err => {
        commit('set_error', extractErrorDetails(err));
        reject();
      });
    });
  },
  removeUObjects({commit, getters, /*rooState*/}, payload) {
    return new Promise((resolve, reject) => {
      let data = {
        // edit_key: rootState.Project.edit_key
      };
      data = Object.assign(data, payload.u_objects);
      axios({
        url: getters.getUrl(payload),
        method: 'PUT',
        data: data,
      })
      .then(() => {
        commit('remove_u_objects', payload);
        resolve();
      })
      .catch(err => {
        commit('set_error', extractErrorDetails(err));
        reject();
      });
    });
  },
  resetUState({commit}, payload) {
    commit('reset_u_state', payload);
  }
};

// Mutations
const mutations = {
  //object mutations
  create_u_objects(state, payload) {
    for (let prop in payload.resp_data) {
        payload.resp_data[prop].forEach(object => {
          if (state[prop][object.id]) {
            Vue.set(state[prop], object.id, Object.assign(state[prop][object.id], object));
          } else if (state.definitions[prop]) {
            let definition = JSON.parse(JSON.stringify(state.definitions[prop]));
            Vue.set(state[prop], object.id, Object.assign(definition, object));
          } else {
            Vue.set(state[prop], object.id, object);
          }
        }
      );
    }
  },
  update_u_object(state, payload) {
    if (payload.bulk) {
      let target_key = Object.keys(payload.url[payload.url.length-2])[0];
      payload.bulk.updates.forEach((update, index) => {
        if (state[target_key][payload.bulk.targets[target_key][index]]) {
          Object.assign(state[target_key][payload.bulk.targets[target_key][index]], update);
        }
      });
    }
    else if (state[Object.keys(payload.url[payload.url.length-1])[0]][Object.values(payload.url[payload.url.length-1])[0]]) {
      Object.assign(state[Object.keys(payload.url[payload.url.length-1])[0]][Object.values(payload.url[payload.url.length-1])[0]], payload.update);

      // ADDITION: DELETION OF BLOCKS IN ARTICLE REACTIVE
      let target_key = Object.keys(payload.url[payload.url.length-1])[0];
      if(target_key === 'textblocks' || target_key === 'mediablocks') {
        if(payload.update.order_index >= 0) {
          let article_id = payload.url[payload.url.length-2].articles;

          let arr = state.articles[article_id].blocks;
          let fromIndex = (payload.is_up) ? payload.update.order_index + 1 : payload.update.order_index - 1;
          let toIndex = payload.update.order_index;
          let element = arr[fromIndex];
          arr.splice(fromIndex, 1);
          arr.splice(toIndex, 0, element);
        }
      }

      if(target_key === 'articles') {
        // ADDITION: DELETION OF AUTHORS IN ADMIN REACTIVE
        if(payload.update['remove#authors']) {
          let article_id = payload.url[payload.url.length-1].articles;
          let rm_author_user_article_id = payload.update['remove#authors'];
          let rm_authors = state.articles[article_id].authors.filter(
            author => rm_author_user_article_id.includes(author.id)
          );

          let arr = state.articles[article_id].authors;
          for(let i = 0; i < rm_authors.length; i++) {
            arr.splice(arr.indexOf(rm_authors[i]), 1);
          }
        }

        // ADDITION: ADDITION OF AUTHORS IN ADMIN REACTIVE
        if(payload.update['add#authors']) {
          let article_id = payload.url[payload.url.length-1].articles;
          let add_authors = payload.update['add#authors'];

          let arr = state.articles[article_id].authors;
          for(let i = 0; i < add_authors.length; i++) {
            let author = payload.update.potential_authors.find(author => author.user_id == add_authors[i]);
            if(author) {
              arr.push(author);
            }
          }
        }
      }

      // END ADDITIONS
    }
  },
  update_u_object_position(state, payload) {
    if (payload.bulk) {
      let target_key = Object.keys(payload.url[payload.url.length-2])[0];
      let parent_key = Object.keys(payload.url[payload.url.length-3])[0];

      payload.bulk.targets[target_key].forEach((target_value, index) => {
        let parent_value = payload.bulk.parents[parent_key][index];

        let array = state[parent_key][parent_value][target_key];

        let old_index = array.indexOf(target_value);
        if (old_index !== -1) {
          let new_index = old_index + parseInt(payload.bulk.updates[index].position);
          if (new_index < 0) {
            new_index = 0;
          } else if (new_index > array.length-1) {
            new_index = array.length-1;
          }
          array.splice(new_index, 0, array.splice(old_index, 1)[0]);
        }
      });
    } else {
      let target_key = Object.keys(payload.url[payload.url.length-1])[0];
      let target_value = Object.values(payload.url[payload.url.length-1])[0];
      let parent_key = Object.keys(payload.url[payload.url.length-2])[0];
      let parent_value = Object.values(payload.url[payload.url.length-2])[0];

      let array = state[parent_key][parent_value][target_key];

      let old_index = array.indexOf(target_value);
      let new_index = old_index + parseInt(payload.update.position);

      array.splice(new_index, 0, array.splice(old_index, 1)[0]);
    }
  },
  // ADDITION: MOVE ARTICLES REACTIVLY IN ADMIN
  update_u_object_order_index(state, payload) {
    let target_key = Object.keys(payload.url[payload.url.length-1])[0];
    let target_value = Object.values(payload.url[payload.url.length-1])[0];
    let parent_key = Object.keys(payload.url[payload.url.length-2])[0];
    let parent_value = Object.values(payload.url[payload.url.length-2])[0];

    let array = state[parent_key][parent_value][target_key];

    let old_index = array.indexOf(target_value);
    let new_index = payload.update.order_index;

    array.splice(new_index, 0, array.splice(old_index, 1)[0]);
  },
  // END ADDITION
  update_u_object_parent(state, payload) {
    if (payload.bulk) {
      let target_key = Object.keys(payload.url[payload.url.length-2])[0];
      let old_parent_key = Object.keys(payload.url[payload.url.length-3])[0];
      let new_parent_key = old_parent_key;

      payload.bulk.targets.forEach((target, index) => {
        let old_parent_value = payload.bulk.old_parents[index];
        let new_parent_value = payload.bulk.parents[index];

        let old_array = state[old_parent_key][old_parent_value][target_key];
        let new_array = state[new_parent_key][new_parent_value][target_key];

        let old_index = old_array.indexOf(target.id);

        old_array.splice(old_index, 1);
        if (target.position) {
          let new_index = target.position - parseInt(1);
          new_array.splice(new_index, 0, target.id);
        } else {
          new_array.push(target.id);
        }
      });
    } else {
      let target_key = Object.keys(payload.url[payload.url.length-2])[0];
      let target_value = Object.values(payload.url[payload.url.length-2])[0];

      let old_parent_key = Object.keys(payload.url[payload.url.length-3])[0];
      let new_parent_key = old_parent_key;
      if (payload.update.target_type) {
        new_parent_key = payload.update.target_type;
      }
      let old_parent_value = Object.values(payload.url[payload.url.length-3])[0];
      let new_parent_value = 0;
      //deprecated only in project structure
      if (payload.update.target_id) {
        new_parent_value = payload.update.target_id;
      } else {
        new_parent_value = payload.update.parent_id;
      }

      let old_array = state[old_parent_key][old_parent_value][target_key];
      let new_array = state[new_parent_key][new_parent_value][target_key];
      let old_index = old_array.indexOf(target_value);

      old_array.splice(old_index, 1);
      if (payload.update.position) {
        let new_index = payload.update.position - parseInt(1);
        new_array.splice(new_index, 0, target_value);
      } else {
        new_array.push(target_value);
      }
    }
  },
  delete_u_object(state, payload) {
    if (payload.bulk) {
      let target_key = Object.keys(payload.url[payload.url.length-2])[0];
      payload.bulk.targets[target_key].forEach(target_id => {
        if (state[target_key][target_id]) {
          Vue.delete(state[target_key], target_id);
        }
      });
    } else {
      let target_key = Object.keys(payload.url[payload.url.length-1])[0];
      let target_value = Object.values(payload.url[payload.url.length-1])[0];
      // ADDITION: DELETION OF BLOCKS IN ARTICLE
      if(target_key === 'textblocks' || target_key === 'mediablocks') {
        let article_id = payload.url[payload.url.length-2].articles;

        state.articles[article_id].blocks =
        [
          ...state.articles[article_id].blocks.filter(block =>
            (
              (block.id !== target_value && block.type === target_key) ||
              block.type !== target_key
            )
          )
        ];
      }
      // END ADDITION
      Vue.delete(state[target_key], target_value);
    }
  },
  //parent mutations
  add_u_object(state, payload) {
    if (payload.bulk) {
      let target_key = Object.keys(payload.url[payload.url.length-3])[0];
      let parent_key = Object.keys(payload.url[payload.url.length-4])[0];
      payload.bulk.targets[target_key].forEach((target_value, target_index) => {
        let parent_value = payload.bulk.parents[parent_key][target_index];
        if (!state[parent_key][parent_value][target_key]) {
          Vue.set(state[parent_key][parent_value], [target_key], []);
        }
        if (payload.resp_data[target_key][target_index].position) {
          state[parent_key][parent_value][target_key].splice(payload.resp_data[target_key][target_index].position-1, 0, payload.resp_data[target_key][target_index].id);
        }
        else {
          state[parent_key][parent_value][target_key].push(payload.resp_data[target_key][target_index].id);
        }
      });
    } else {
      let target_key = Object.keys(payload.url[payload.url.length-2])[0];
      let parent_key = Object.keys(payload.url[payload.url.length-3])[0];
      let parent_value = Object.values(payload.url[payload.url.length-3])[0];
      if (!state[parent_key][parent_value][target_key]) {
        Vue.set(state[parent_key][parent_value], [target_key], []);
      }
      payload.resp_data[target_key].forEach(target => {
        if (!state[parent_key][parent_value][target_key].includes(target.id)) {
          if (target.position) {
            state[parent_key][parent_value][target_key].splice(target.position-1, 0, target.id);
          }
          else {
            state[parent_key][parent_value][target_key].push(target.id);
          }
        }
      });
      // ADDITION: CREATION OF BLOCKS IN ARTICLE
      if(target_key === 'textblocks' || target_key === 'mediablocks') {
        let article_id = parent_value;
        if (!state[parent_key][parent_value]['blocks']) {
          Vue.set(state[parent_key][parent_value], ['blocks'], []);
        }
        payload.resp_data[target_key].forEach(target => {
          state.articles[article_id].blocks.splice(
            target.order_index, 0, {
               id: target.id,
               type: target_key,
               order_index: target.order_index
             }
           );
        });
      }
      // END ADDITION
    }
  },
  remove_u_object(state, payload) {
    if (payload.bulk) {
      let target_key = Object.keys(payload.url[payload.url.length-2])[0];
      let parent_key = Object.keys(payload.url[payload.url.length-3])[0];
      payload.bulk.targets[target_key].forEach((target_value, target_index) => {
        let parent_value = payload.bulk.parents[parent_key][target_index];
        let index = state[parent_key][parent_value][target_key].indexOf(target_value);
        if (index !== -1) {
          state[parent_key][parent_value][target_key].splice(index, 1);
        }
      });
    }
    else {
      let target_key = Object.keys(payload.url[payload.url.length-1])[0];
      let target_value = Object.values(payload.url[payload.url.length-1])[0];
      let parent_key = Object.keys(payload.url[payload.url.length-2])[0];
      let parent_value = Object.values(payload.url[payload.url.length-2])[0];
      let index = state[parent_key][parent_value][target_key].indexOf(target_value);
      state[parent_key][parent_value][target_key].splice(index, 1);
    }
  },
  add_u_objects(state, payload) {
    let parent_key = Object.keys(payload.url[payload.url.length-2])[0];
    let parent_value = Object.values(payload.url[payload.url.length-2])[0];

    let target_key = payload.u_objects.target_type;
    let target_value = payload.u_objects.target_ids;

    if (payload.u_objects.start_index) {
      state[parent_key][parent_value][target_key].splice(payload.u_objects.start_index, 0, target_value);
    } else {
      state[parent_key][parent_value][target_key] = state[parent_key][parent_value][target_key].concat(target_value);
    }
  },
  remove_u_objects(state, payload) {
    let parent_key = Object.keys(payload.url[payload.url.length-2])[0];
    let parent_value = Object.values(payload.url[payload.url.length-2])[0];

    let target_key = payload.u_objects.target_type;
    let target_value = payload.u_objects.target_ids;

    target_value.forEach(id => {
      let index = state[parent_key][parent_value][target_key].indexOf(id);
      state[parent_key][parent_value][target_key].splice(index, 1);
    });
  },
  reset_u_state(state, payload) {
    payload.keys.forEach(key => {
      Vue.set(state, key, initialState()[key]);
    });
  },
  set_error(state, error) {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
