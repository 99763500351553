import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';

Vue.use(VueRouter);

// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import( /* webpackChunkName: "Home" */ '@/views/Home.view.vue'),
    beforeEnter: (to, from, next) => {
      store.dispatch('Knowledge/requestLatestArticles').then(() => { next(); });
    },
  },

  {
    path: '/search/:search_params',
    name: 'Search',
    component: () => import( /* webpackChunkName: "Result" */ '@/views/Result.view.vue'),
    props: () => {
      return {
        title: (store.getters['Knowledge/getSelectedLanguage']() === 'de') ? 'Suchergebnisse' : 'Search results',
        article_ids: store.getters['Knowledge/getLatestSearchResult'](),
        is_search: true,
      };
    },
    beforeEnter: (to, from, next) => {
      if(to.search_params != store.getters['Knowledge/getLatestSearchTerms']()) {
        store.dispatch('Knowledge/requestSearchResults', { search_params: to.params.search_params }).then(() => {  next(); });
      }
      else {
        next();
      }
    },
  },

  {
    path: '/category/:category_slug',
    name: 'Category',
    component: () => import( /* webpackChunkName: "Result" */ '@/views/Result.view.vue'),
    props: (route) => {
      let id = Object.values(store.state.Ultra.categories).find(category => category.slug === route.params.category_slug).id;
      return {
        title: store.state.Ultra.categories[id].name,
        article_ids: store.state.Ultra.categories[id].articles,
      };
    },
    beforeEnter: (to, from, next) => {
      store.dispatch('Knowledge/requestCategoryBySlug', { category_slug: to.params.category_slug }).then(() => {  next(); });
    },
  },

  {
    path: '/latest',
    name: 'LatestArticles',
    component: () => import( /* webpackChunkName: "Result" */ '@/views/Result.view.vue'),
    props: () => {
      return {
        title: (store.getters['Knowledge/getSelectedLanguage']() == 'de') ? 'NEUSTE.artikel' : 'LATEST.articles',
        article_ids: store.getters['Knowledge/getLatestArticles'](),
      };
    },
    beforeEnter: (to, from, next) => {
      store.dispatch('Knowledge/requestLatestArticles').then(() => {  next(); });
    },
  },

  {
    path: '/category/:category_slug/articles/:article_id',
    name: 'Article',
    component: () => import( /* webpackChunkName: "Article" */ '@/views/Article.view.vue'),
    props: (route) => {
      return {
        article: store.getters['Ultra/getUObject']({
          type: 'articles',
          id: route.params.article_id,
        }) || {  },
        potential_authors: store.getters['Knowledge/getSimplePotentialAuthors'](),
      };
    },
    beforeEnter: (to, from, next) => {
      store.dispatch('Knowledge/requestCategoryBySlug', { category_slug: to.params.category_slug })
      .then((resp) => {
        let match = Object.values(store.state.Ultra.articles).find(article => article.id === to.params.article_id);
        if(!match || match.blocks.length == 0) {
          store.dispatch('Ultra/requestUObject', {
            url: [
              { categories: resp.id },
              { articles: to.params.article_id },
              { showWithEntitiesNormalized: '' },
            ],
          });
        }
      })
      .then(() => {
        next();
      });
    },
  },

  {
    path: '/admin/category/:category_slug/articles/:article_id',
    name: 'AdminArticle',
    component: () => import( /* webpackChunkName: "Article" */ '@/views/Article.view.vue'),
    meta: {
      requiresAuth: true,
    },
    props: (route) => {
      return {
        article: store.getters['Ultra/getUObject']({
          type: 'articles',
          id: route.params.article_id,
        }) || {  },
        potential_authors: store.getters['Knowledge/getSimplePotentialAuthors'](),
      };
    },
    beforeEnter: (to, from, next) => {
      store.dispatch('Knowledge/requestCategoryBySlug', { category_slug: to.params.category_slug })
      .then((resp) => {
        let match = Object.values(store.state.Ultra.articles).find(article => article.id === to.params.article_id);
        if(!match || match.blocks.length == 0) {
          store.dispatch('Ultra/requestUObject', {
            url: [
              { categories: resp.id },
              { articles: to.params.article_id },
              { showWithEntitiesNormalized: '' },
            ],
          });
        }
      })
      .then(() => {
        next();
      });
    },
  },

  {
    path: '/legal',
    name: 'Legal',
    component: () => import( /* webpackChunkName: "Legal" */ '@/views/Legal.view.vue'),
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import( /* webpackChunkName: "Login" */ '@/views/Admin/Login.view.vue')
  },

  {
    path: '/admin',
    name: 'Admin',
    component: () => import( /* webpackChunkName: "Admin" */ '@/views/Admin/Admin.view.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'articles',
        name: 'ArticlesDashboard',
        component: () => import ( /*webpackChunkName: 'ArticlesDashboard' */ '@/views/Admin/ArticlesDashboard.view.vue'),
        props: () => {
          return {
            categories:  store.getters['Ultra/getUObjects']({ type: 'categories' }),
            potential_authors: store.getters['Knowledge/getSimplePotentialAuthors'](),
          };
        },
      },
      {
        path: 'categories',
        name: 'CategoriesDashboard',
        component: () => import ( /*webpackChunkName: 'CategoriesDashboard' */ '@/views/Admin/CategoriesDashboard.view.vue'),
        props: () => {
          return {
            languages: store.getters['Knowledge/getLanguages'](),
          };
        },
      },
    ],
    beforeEnter: (to, from, next) => {
      if(store.getters['Auth/hasAllOfPermissions'](
        [
          'Category CREATE', 'Category READ', 'Category UPDATE',
          'Category DELETE', 'Article CREATE', 'Article READ',
          'Article UPDATE', 'Article DELETE',
        ]
      )) {
        store.dispatch('Knowledge/updatePotentialAuthors')
        .then(() => {
          next();
        });
      }
      else {
        next({
          name: 'Home',
        })
      }
    },
  },

  {
    path: '/aws-auth',
    name: 'AWSAuth',
    component: () => import( /* webpackChunkName: "LegalNotice" */ '@/views/Auth/AWSAuth.view.vue'),
  },

  {
    path: '/aws-redirect',
    name: 'AWSRedirect',
    component: () => import( /* webpackChunkName: "LegalNotice" */ '@/views/Auth/AWSRedirect.view.vue'),
  },

]

const router = new VueRouter({
  routes: routes,
  mode: 'history'
});

router.beforeEach((to, from, next) => {
  // store is defined here
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['Auth/isLoggedIn']) {
      if (store.getters['Auth/getUserId'] === 0) {
        store.dispatch('Auth/initUser').then( () => {
          next();
        }).catch((e) => { console.log(e); });
      } else {
        next();
      }
    } else {
      next({
        name: 'Home',
        // name: 'AWSRedirect',
        // query: {
        //   redirect: to.fullPath
        // }
      });
    }
  } else if(store.getters['Auth/isLoggedIn'] && store.getters['Auth/getUserId'] === 0) {
    store.dispatch('Auth/initUser').then( () => {
      next();
    }).catch((e) => { console.log(e); });
  }
  else {
    next();
  }
});

export default router;
