<template>
  <v-app>
    <!-- HEADER -->
    <v-app-bar
      color="#272727"
      dark
      app
    >
      <!-- Linke Seite -->
      <v-btn
        :href="getHref('Home')"
        @click.left.prevent="navigateToRoute('Home')"
        icon
      >
        <Logo />
      </v-btn>
      <v-toolbar-title v-if="!$vuetify.breakpoint.smAndDown">TAKT.knowledge</v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- Rechte Seite -->
      <div
        class="search"
        :style="$vuetify.breakpoint.smAndDown ? 'padding-left: 3%; width: 100%;' : ''"
        @mouseover="search_is_hovered=true"
        @mouseleave="search_is_hovered=false"
      >
        <v-text-field
          v-model="search_input"
          outlined
          clearable
          :placeholder="(getSelectedLanguage() === 'de') ? 'Suche' : 'Search'"
          rounded
          dense
          hide-details
          height=24
          @focus="search_is_focused=true"
          @blur="search_is_focused=false"
          @keydown.enter="search()"
        >
        <!-- append-icon="mdi-magnify"-->
           <v-icon
            slot="append"
            :color="
              (search_is_focused || search_is_hovered || search_input != '')
                ? '#FFF'
                : '#555555'
              "
            @click="search()"
          >
            mdi-magnify
          </v-icon>
        </v-text-field>
      </div>
      <v-btn
        v-if="isLoggedIn && hasAllOfPermissions(admin_perms)"
        :href="getHref('ArticlesDashboard')"
        @click.left.prevent="navigateToRoute('ArticlesDashboard')"
        id="admin-dashboard-btn"
        icon
        dense
        style="display:block"
      >
        <p id="admin-btn-description">admin</p>
        <AdminIcon />
      </v-btn>
      <LogoutBtn />
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main class="bg">

      <!-- Provides the application the proper gutter -->
      <v-container fluid>

        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <!-- FOOTER -->
    <v-footer
      padless
      app
    >
      <div class="footer">
        <!-- Linke Seite -->
        <p v-if="!$vuetify.breakpoint.smAndDown" class="">
          {{ version_number }} — <strong>TAKT.ing</strong>
        </p>
        <v-spacer v-if="!$vuetify.breakpoint.smAndDown" />

        <!-- Mittelteil -->
        <div>
          <v-btn
            v-for="link in footer_links"
            :key="link.link"
            :class="$vuetify.breakpoint.smAndDown ? 'mx-2 white--text' : 'mx-4 white--text'"
            icon
            :href="`${link.link}`"
            target="_blank"
          >
            <v-icon v-if="link.icon" size="24px">
              {{ link.icon }}
            </v-icon>
            <LogoWhite v-else style="width:24px" />
          </v-btn>
        </div>
        <v-spacer />

        <!-- Rechte Seite -->
        <v-btn
          class="mx-4 white--text"
          color="transparent"
          style="text-transform:none;letter-spacing:normal;"
          :href="getHref('Legal')"
          @click.left.prevent="navigateToRoute('Legal')"
          elevation="0"
        >
          <u>legal notice</u>
        </v-btn>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import Logo from '@/components/SVG/Logo.component.vue';
import LogoWhite from '@/components/SVG/LogoWhite.component.vue';
import AdminIcon from '@/components/SVG/AdminIcon.component.vue';
import LogoutBtn from '@/components/LogoutBtn.component.vue';
import { Navigation } from '@/mixins/Navigation.mixin.js';
import { Ultra } from '@/mixins/Ultra.mixin.js';
import { Knowledge } from '@/mixins/Knowledge.mixin.js';
import { Helpers } from '@/mixins/Helpers.mixin.js';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'App',

  components: {
    Logo,
    LogoWhite,
    AdminIcon,
    LogoutBtn,
  },

  mixins: [Navigation, Ultra, Knowledge, Helpers, ],

  computed: {
      ...mapGetters('Auth', ['isLoggedIn', ]),
  },

  data: () => ({
    search_input: '',
    search_is_focused: false,
    search_is_hovered: false,
    version_number: '',
    footer_links: [
      {
        link: 'https://takting.com/',
      },
      {
        link: 'https://de.linkedin.com/company/takt-ing',
        icon: 'mdi-linkedin',
      },
      {
        link: 'https://www.youtube.com/channel/UC2QoKazqAfnJeolHcz8w6iw',
        icon: 'mdi-youtube',
      },
    ],
    admin_perms: [
        'Category CREATE', 'Category READ', 'Category UPDATE',
        'Category DELETE', 'Article CREATE', 'Article READ',
        'Article UPDATE', 'Article DELETE',
      ],
  }),

  methods: {
    ...mapActions('Auth', [
       'initUser'
     ]),

    search() {
      if(this.search_input) {
        this.navigateToSearch(
          this.generateSearchString(
            this.search_input.split(/\s+/)
          )
        );
      }
    },
  },

  created() {
    if (this.$vuetify.breakpoint.smAndDown) {
      document.body.style.setProperty('--h1-font-size', '18pt');
      document.body.style.setProperty('--h6-font-size', '9pt');
      document.body.style.setProperty('--p-font-size-article', '11pt');
      document.body.style.setProperty('--p-font-size-media', '9pt');
      document.body.style.setProperty('--ol-ul-margin-left', '25px');
    }
    else {
      document.body.style.setProperty('--h1-font-size', '36pt');
      document.body.style.setProperty('--h6-font-size', '11pt');
      document.body.style.setProperty('--p-font-size-article', '15pt');
      document.body.style.setProperty('--p-font-size-media', '13pt');
      document.body.style.setProperty('--ol-ul-margin-left', '50px');
    }

    let json = require('../package.json');
    this.version_number = json.version;

    // if (this.isLoggedIn) {
    //   // returns promise
    //   this.initUser();
    // }
  },

  watch: {
    '$vuetify.breakpoint.smAndDown': function(val) {
      if(val) {
        document.body.style.setProperty('--h1-font-size', '18pt');
        document.body.style.setProperty('--h6-font-size', '9pt');
        document.body.style.setProperty('--p-font-size-article', '11pt');
        document.body.style.setProperty('--p-font-size-media', '9pt');
        document.body.style.setProperty('--ol-ul-margin-left', '25px');
      }
      else {
        document.body.style.setProperty('--h1-font-size', '36pt');
        document.body.style.setProperty('--h6-font-size', '11pt');
        document.body.style.setProperty('--p-font-size-article', '15pt');
        document.body.style.setProperty('--p-font-size-media', '13pt');
        document.body.style.setProperty('--ol-ul-margin-left', '50px');
      }
    },
  },
}
</script>

<style>
:root {
  --h1-font-size: 36pt;
  --h6-font-size: 11pt;
  --p-font-size-article: 15pt;
  --p-font-size-media: 13pt;
  --ol-ul-margin-left: 50px;
  --thumbnail-width-static: 310;
  --grid-space: 10;
}
.help {
  border-style: solid;
  border-width: 1px;
}
.bg {
  background: #121212;
  color: white;
}
.search {
  width: 300px;
  padding-right: min(24px, 5%);
}
.footer {
  background: #272727;
  color: white;
  display: flex;
  padding: 0 16px;
  width: 100%;
  height: 50px;
  align-items: center;
}
#admin-dashboard-btn {
  margin-right: min(24px, 5%);
}
#admin-dashboard-btn:hover #admin-btn-description{
  display: block;
}
#admin-btn-description {
  display: none;
  position: absolute;
  width: 100%;
  /* height: 100%; */
  z-index:10;
  letter-spacing: normal;
  text-transform: none;
  text-shadow: 0px 2px 1px #000;
}
.v-application p {
  margin-bottom: 0 !important;
}
.my-button {
  padding: 0 !important;
  border: none !important;
}
.author-name {
  display: inline-block;
}
.is-active {
  background: #FFF;
  color: #000 !important;
  border-color: #000 !important;
}
.hint {
  font-size: 10pt;
  padding-top: 20px;
  text-align: right;
  color: #E53935;
}
.ProseMirror > p,
.ProseMirror > ul > li,
.ProseMirror > ol > li,
.ProseMirror > blockquote,
.textblock-p {
  text-align: left;
  font-size: var(--p-font-size-article);
  font-weight: 300;
  color: white;
}

h1 {
  font-weight: 200;
  font-size: var(--h1-font-size);
}
h6 {
  font-weight: 300;
  font-size: var(--h6-font-size);
  font-style: italic;
  margin-bottom: 8px;
}
a {
  color: #1976D2;
  text-decoration: none;
}
p > a:hover {
  color: #265D93;
  text-decoration: underline;
}

/* for ordered lists in textblocks */
ol, ul {
  margin-left: var(--ol-ul-margin-left);
}

/* to highlight and to prevent equations from breaking */
code {
  display: inline-block;
  font-size: 100%;
  padding: 0;
}

/* responsive table*/
/* TODO: drag this into Tiptap.component.vue*/
/* https://codepen.io/AllThingsSmitty/pen/MyqmdM?editors=1100 */
.textblock-table,
.ProseMirror > table {
  border-collapse: collapse;
  border: 1px solid #ccc;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  padding: 0;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

.textblock-table tr {
  background-color: #121212;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .textblock-table > table {
    border: 0;
  }

  /* .ProseMirror > table > tbody > tr > th, */
  .table-thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .textblock-table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }

  .textblock-table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  .textblock-table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }

  .textblock-table td:last-child {
    border-bottom: 0;
  }
}
</style>
