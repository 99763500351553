/*jshint esversion: 6 */

function comparePosition(a, b){
  if ( a.position < b.position) {
    return -1;
  }
  if (a.position > b.position) {
    return 1;
  }
  return 0;
}

function comparePoints(a, b){
  if ( a.points > b.points) {
    return -1;
  }
  if (a.points < b.points) {
    return 1;
  }
  return 0;
}

function getDateString(entry) {
  // parse entry date
  let sec = parseInt(entry.created.substr(17,2));
  let min = parseInt(entry.created.substr(14,2));
  let hour = parseInt(entry.created.substr(11,2));
  let day = parseInt(entry.created.substr(8,2));
  let month = parseInt(entry.created.substr(5,2));
  let year = parseInt(entry.created.substr(0,4));
  let entry_date = new Date(year, month-1, day, hour, min, sec);

  let today = new Date();
  let yesterday = new Date(new Date().setDate(new Date().getDate()-1));
  if (entry_date.getFullYear() === today.getFullYear() &&
      entry_date.getMonth() === today.getMonth() &&
      entry_date.getDate() === today.getDate()) {
  return `today at ${entry_date.getHours() < 10? '0':''}${entry_date.getHours()}:`+
                    `${entry_date.getMinutes() < 10? '0':''}${entry_date.getMinutes()}`;
} else if (entry_date.getFullYear() === today.getFullYear() &&
    entry_date.getMonth() === today.getMonth() &&
    entry_date.getDate() === yesterday.getDate()) {
  return `yesterday at ${entry_date.getHours() < 10? '0':''}${entry_date.getHours()}:`+
                        `${entry_date.getMinutes() < 10? '0':''}${entry_date.getMinutes()}`;
} else {
  return `${entry_date.getFullYear()}-`+
          `${entry_date.getMonth() < 10? '0':''}${entry_date.getMonth()+1}-`+
          `${entry_date.getDate() < 10? '0':''}${entry_date.getDate()} at `+
          `${entry_date.getHours() < 10? '0':''}${entry_date.getHours()}:`+
          `${entry_date.getMinutes() < 10? '0':''}${entry_date.getMinutes()}`;
  }
}

function extractErrorDetails(err){
  console.log(err);
  return {
    status: err.response.status,
    statusText: err.response.statusText,
    msg: err.response.data.error_msg
  };
}

function getHumanReadableKeys(obj){
  let keys = [];
  for(let key of Object.keys(obj)){
    keys.push(key.replace(/_/g, ' '));
  }
  return keys;
}

function getFileTypeFromExtenion(ext) {
  let type = 'other';
  if (ext == 'bmp' || ext == 'jpg' || ext == 'jpeg' || ext == 'gif' || ext == 'png') {
    type = 'image';
  } else if (ext == 'pdf') {
    type = 'pdf';
  }
  return type;
}

module.exports = {
  comparePosition: comparePosition,
  getDateString: getDateString,
  comparePoints: comparePoints,
  extractErrorDetails: extractErrorDetails,
  getHumanReadableKeys: getHumanReadableKeys,
  getFileTypeFromExtenion: getFileTypeFromExtenion
};
