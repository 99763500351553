import axios from 'axios';

const initialState = () => ({
  languages: ['de', 'en'],
  selected_language: localStorage.getItem('takt_language') || 'de',

  latest_search_result_ids: [],
  latest_search_terms: [],
  latest_articles_ids: { de: [], en: [], },

  potential_authors: [],
  simple_potential_authors: [],
});

const state = initialState();

const getters = {

  getLanguages: (state) => () => {
    return state.languages;
  },

  getSelectedLanguage: (state) => () => {
    return state.selected_language;
  },

  getLatestSearchResult: (state) => () => {
    return state.latest_search_result_ids;
  },

  getLatestSearchTerms: (state) => () => {
    return state.latest_search_terms;
  },

  getLatestArticles: (state) => () => {
    return state.latest_articles_ids[state.selected_language];
  },

  getPotentialAuthors: (state) => () => {
    return state.potential_authors;
  },

  getSimplePotentialAuthors: (state) => () => {
    return state.simple_potential_authors;
  },

  getMatchedSimplePotentialAuthors: (state) => (authors_arr) => {
    let user_ids = authors_arr.map(author => author.user_id);
    let ret = [];
    for(let i = 0; i < user_ids.length; i++) {
      ret.push(Object.values(state.simple_potential_authors).find(author => author.user_id === user_ids[i]));
    }

    return ret;
  },
};

const actions = {
  setLanguage({ commit, dispatch, getters, rootState, rootGetters }, payload) {
    if(payload.nevertrue) {
      console.log(getters);
      console.log(rootState);
    }

    if(payload.language) {
      commit("set_language", payload);

      localStorage.setItem('takt_language', payload.language);
      if(rootGetters['Ultra/getUObjectsFiltered']({
        type: 'categories',
        filter: {
          language: payload.language,
          published: 'public',
        },
      }).length == 0) {
        dispatch('Ultra/requestUObject', {
          url: [
            { categories: '' },
            { showWithEntitiesNormalized: '' },
          ],
          url_suffix: `&published=public&language=${payload.language}`,
        }, { root: true }).then(() => {
          dispatch('requestLatestArticles');
        });
      }
    }
  },

  uploadImage({ commit, getters, rootState, rootGetters }, payload) {
    if(payload.nevertrue) {
      console.log(getters);
      console.log(rootState);
    }

    return new Promise((resolve, reject) => {
      let image = new FormData();
      image.append("image", payload.image);

      axios({
        url: rootGetters['Ultra/getUrl'](payload),
        method: 'POST',
        data: image,
        headers: {
          'Cache-Control' : 'no-cache'
        },
      })
      .then(resp => {
        commit(
          'Ultra/update_u_object',
          {
            url: payload.url,
            update: { [`${payload.update.key}`]: eval(`resp.data.data.${payload.update.value_suffix}`), },
          },
          { root: true },
        );
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        reject();
      });
    });
  },

  setLatestSearchResult({ commit }, payload) {
    if(payload.article_ids) {
      commit("set_latest_search_result", payload);
    }
  },

  setLatestSearchTerms({ commit }, payload) {
    if(payload.search_terms) {
      commit("set_latest_search_terms", payload);
    }
  },

  setLatestArticles({ commit }, payload) {
    if(payload.article_ids) {
      commit("set_latest_articles", payload);
    }
  },

  requestLatestArticles({dispatch, getters }) {
      return new Promise ((resolve, reject) => {
        if(getters.getLatestArticles() !== []) {
          dispatch('Ultra/requestUObject', {
            url: [
              { categories: '0' },
              { articles: '' },
              { showWithEntitiesNormalized: '' },
            ],
            url_suffix: `&latest=true&language=${getters.getSelectedLanguage()}`,
          }, { root: true })
          .then((resp) => {
            let arr = resp.articles;
            let latestArticleIds = [];
            for(let i = 0; i < arr.length; i++) {
              latestArticleIds.push(arr[i].id);
            }
            dispatch('setLatestArticles', { article_ids: latestArticleIds });
            resolve();
          })
          .catch(() => {
            reject();
          });
        }
        else {
          resolve();
        }
      }
    );
  },

  requestCategoryBySlug({ dispatch, rootState }, payload) {
    return new Promise ((resolve, reject) => {
      let match = Object.values(rootState.Ultra.categories).find(category => category.slug === payload.category_slug);
      if(!match) {
        dispatch('Ultra/requestUObject', {
          url: [
            { categories: '' },
            { showWithEntitiesNormalized: '' },
          ],
          url_suffix: `&slug=${payload.category_slug}`
        }, { root: true })
        .then((resp) => {
          resolve(resp.categories[0]);
        })
        .catch(() => {
          reject();
        });
      }
      else {
        resolve(match);
      }
    })
  },

  requestSearchResults({ dispatch }, payload) {
    return new Promise ((resolve, reject) => {
      dispatch('setLatestSearchTerms', { search_terms: payload.search_params });
      dispatch('Ultra/requestUObject', {
        url: [
          { categories: '0' },
          { articles: '' },
          { showWithEntitiesNormalized: '' },
        ],
        url_suffix: payload.search_params,
      }, { root: true })
      .then((resp) => {
        let arr = resp.articles;
        let searchArticleIds = [];
        for(let i = 0; i < arr.length; i++) {
          searchArticleIds.push(arr[i].id);
        }

        dispatch('setLatestSearchResult', { article_ids: searchArticleIds });
        resolve();
      })
      .catch(() => {
        reject();
      });
    });
  },

  cleanHashtagArray({ commit, getters, rootState, rootGetters }, payload) {
    if(payload.nevertrue) {
      console.log(getters);
      console.log(rootState);
    }

    if(payload.hashtag_id) {
      let arr = rootGetters['Ultra/getUObject']({ type: 'articles', id: payload.article_id }).hashtags;
      commit("clean_hashtag_array", { array: arr, hashtag_id: payload.hashtag_id });
    }
  },

  updatePotentialAuthors({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'users?role=27',
        method: 'GET',
      })
      .then(resp => {
        commit(
          'set_potential_authors', resp.data.data
        );
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        reject();
      });
    });
  },
};

const mutations = {
  set_language(state, payload) {
    state.selected_language = payload.language;
  },

  clean_hashtag_array(state, payload) {
    let hashtag = Object.values(payload.array).find(element => element.id === payload.hashtag_id);
    payload.array.splice(payload.array.indexOf(hashtag), 1);
  },

  set_latest_search_result(state, payload) {
    state.latest_search_result_ids = payload.article_ids;
  },

  set_latest_search_terms(state, payload) {
    state.latest_search_terms = payload.search_terms;
  },

  set_latest_articles(state, payload) {
    state.latest_articles_ids[state.selected_language] = payload.article_ids;
  },

  set_potential_authors(state, payload) {
    state.potential_authors = [...payload];

    // make sure every author has a user_id
    for(let i = 0; i < state.potential_authors.length; i++) {
      if(!state.potential_authors[i].user_id) {
        state.potential_authors[i].user_id = state.potential_authors[i].id;
      }
      state.simple_potential_authors.push({
        name: `${state.potential_authors[i].first_name[0]}. ${state.potential_authors[i].last_name}`,
        fullname: `${state.potential_authors[i].first_name} ${state.potential_authors[i].last_name}`,
        user_id: state.potential_authors[i].user_id,
        user_article_id: state.potential_authors[i].id,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
