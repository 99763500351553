import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';

import vuetify from './plugins/vuetify';
import './plugins/axios';
import Axios from 'axios';

Vue.config.productionTip = false


// Axios
Vue.prototype.$http = Axios;
const token = localStorage.getItem('TAKT_token');
if (token) {
  Vue.prototype.$http.defaults.headers.common['Token'] = token;
}

if (process.env.NODE_ENV == 'production') {
  Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_BASE_URL_SERVER;
} else {
  Vue.prototype.$http.defaults.baseURL = '/dev-api/API/public/api/v1/';
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
